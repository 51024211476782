import { useMutation } from '@apollo/client';
import { Box, Button, styled, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';

import i18n from '../../i18n';
import {
  lotIsPending,
  lotIsLive,
  lotIsClosed,
  userIsAuctioneer,
} from '../../modules/portal';
import {
  OPEN_LOT,
  PLACE_BID,
  HAMMER_LOT,
  CLOSE_LOT,
} from '../../schema/portal';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const Bid = ({ lot }) => {
  const [safetyLock, setSafetyLock] = React.useState(false);

  const { user } = useAuth();
  const [openLot] = useMutation(OPEN_LOT);
  const [placeBid] = useMutation(PLACE_BID);
  const [hammerLot] = useMutation(HAMMER_LOT);
  const [closeLot] = useMutation(CLOSE_LOT);
  const { t } = useTranslation();

  const incrementBid = React.useCallback(async () => {
    const lotId = lot.portalInfo._id;
    const oldAmount = lot.portalInfo.currentBid;
    const newAmount = oldAmount ? oldAmount + lot.portalInfo.increment : lot.portalInfo.limit;
    await placeBid({ variables: { bid: { lotId, oldAmount, newAmount } } });
  }, [lot && lot.portalInfo, placeBid]);

  React.useEffect(() => {
    setSafetyLock(true);
    const timer = setTimeout(() => {
      setSafetyLock(false);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [lot.portalInfo.currentBid, lot.portalInfo.increment]);

  const currentBid = lot.portalInfo.currentBid
    ? i18n.currency(lot.portalInfo.currentBid, { decimals: 0, withSymbol: true })
    : '—';

  const nextBid = i18n.currency(
    lot.portalInfo.currentBid ? lot.portalInfo.currentBid + lot.portalInfo.increment : lot.portalInfo.limit,
    { decimals: 0, withSymbol: true },
  );

  return (
    <StyledBox>
      <TextField
        label={t('portal.bid')}
        value={currentBid}
        disabled
      />
      {lotIsPending(lot) ? <>
        <div>{t('portal.lotPending')}</div>
        {userIsAuctioneer(user) ?
          <Button onClick={() => { openLot({ variables: { lotId: lot.portalInfo._id } }); }}>
            {t('portal.openLot')}
          </Button>
        : null}
      </> : null}
      {lotIsLive(lot) ? <>
        <Button disabled={safetyLock} onClick={incrementBid} variant="contained" color="primary">
          {t('portal.placeBid')} ({nextBid})
        </Button>
        {userIsAuctioneer(user) ? <>
          <Button onClick={() => { hammerLot({ variables: { lotId: lot.portalInfo._id } }); }}>
            {t('portal.hammerLot')}
          </Button>
          <Button onClick={() => { closeLot({ variables: { lotId: lot.portalInfo._id } }); }}>
            {t('portal.closeLot')}
          </Button>
        </> : null}
      </> : null}
      {lotIsClosed(lot) ? <>
        <div>{t('portal.lotClosed')}</div>
        {userIsAuctioneer(user) ?
          <Button onClick={() => { openLot({ variables: { lotId: lot.portalInfo._id } }); }}>
            {t('portal.reopenLot')}
          </Button>
        : null}
      </> : null}
    </StyledBox>
  );
};

Bid.propTypes = {
  lot: PropTypes.shape({
    portalInfo: PropTypes.shape({
      _id: PropTypes.string,
      currentBid: PropTypes.number,
      increment: PropTypes.number,
      limit: PropTypes.number,
    }),
  }).isRequired,
};

export default Bid;
