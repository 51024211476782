import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useAuth } from 'react-oidc-context';

import { getUserName } from '../modules/portal';

const PortalButton = () => {
  const { user } = useAuth();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const iconStyle = !mobile ? {
    color: 'background.lighterblue',
    fontSize: '3rem',
    mb: '0.5rem',
  } : null;

  return (
    <Button
      aria-label="My Rippon Boswell"
      href='/portal'
      sx={{
        color: 'white',
        display: 'flex',
        flexDirection: mobile ? 'row' : 'column',
        minWidth: mobile ? 0 : 'inherit',
        alignItems: 'center',
      }}
    >
      <AccountCircleIcon sx={{ ...iconStyle }} />
      {!mobile ? <Typography sx={{ fontSize: '0.8rem' }}>
        {getUserName(user) || 'My Rippon Boswell'}
      </Typography> : null}
    </Button>
  );
};

export default PortalButton;
