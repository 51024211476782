import axios from 'axios';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useAuctions } from './AuctionProvider';
import { useCache } from './CacheProvider';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
const api_endpoint = process.env.REACT_APP_API_ENDPOINT;

const LotsContext = React.createContext({});

const useLots = () => {
  const context = React.useContext(LotsContext);
  const {
    activeLot,
    activeLotImage,
    auction,
    filteredLots,
    loading,
    lots,
    setActiveLot,
    setActiveLotImage,
    setFilteredLots,
    type,
  } = context;
  const count = filteredLots?.length || 0;
  const total = lots?.length || 0;

  return {
    activeLot,
    activeLotImage,
    auction,
    count,
    filteredLots,
    loading,
    list: lots,
    setActiveLot,
    setActiveLotImage,
    setFilteredLots,
    total,
    type,
  };
};

// eslint-disable-next-line react/display-name
const withLots = (Component) => (props) => {
  const lots = useLots();

  return <Component {...props} lots={lots} />;
};

const filterLots = (lots, type, auction) => {
  if (!lots) return [];
  switch (type) {
    case 'aftersale':
      return lots.filter((l) => l.surcharge === undefined || l.surcharge === 0);
    case 'results':
      if (auction.priceDisplayResult === 'hammerprice') {
        return lots.filter((l) => l.surcharge > 0);
      }
      return lots.filter((l) => l.result > 0);
    case 'highlights':
      return lots.filter((l) => {
        if (l.auction.priceDisplayHighlight === 'hammerprice') {
          return l.surcharge > 0;
        }
        return l.result > 0;
      });
    case 'online-catalog':
    default:
      return lots;
  }
};

const fetchSearchResults = async (setData, setLoading, phrase, type, controller) => {
  let aborted = false;
  if (controller) {
    controller.signal.addEventListener('abort', () => { aborted = true; });
  }
  if (aborted) return;
  setLoading(true);
  const response = await axios.get(`${api_endpoint}/lotsearch?phrase=${phrase}&type=${type}`);
  if (aborted) return;
  setData(response?.data?.lots || []);
  if (aborted) return;
  setLoading(false);
}

const addRef = (lot) => (lot ? {
  ...lot,
  ref: React.createRef(),
} : null);

const LotsProvider = ({
  auction = {},
  children = null,
  search = false,
  type = 'online-catalog',
}) => {
  const cache = useCache();
  const [searchParams] = useSearchParams();
  const afterSale = searchParams.get('aftersale') !== null && auction.afterSale;
  const phrase = searchParams.get('q') || '';
  const navigate = useNavigate();
  const { list } = useAuctions();
  const { lotNumber } = useParams();
  const [activeLotImage, setActiveLotImage] = React.useState(0);
  const [lots, setLots] = React.useState([]);
  const [filteredLots, setFilteredLots] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const endpoint = type === 'highlights' ? 'lots/highlights' : `lots/${auction.number}`;
  const newType = afterSale ? 'aftersale' : type;

  const activeLot = React.useMemo(() => {
    const lotParamParts = lotNumber && lotNumber.split('-');
    if (lotParamParts && lotParamParts.length > 1) {
      const highlightAuction = lotParamParts[0] && list.find((l) => l.number == parseInt(lotParamParts[0], 10));
      return (lotParamParts[1] && lots.find((l) => l.auctionId === highlightAuction._id && l.number === parseInt(lotParamParts[1], 10))?._id)
    }
    return (lotNumber && lots.find((l) => l.number === parseInt(lotNumber, 10))?._id) || 0;
  }, [list, lotNumber, lots]);

  const setActiveLot = React.useCallback((id) => {
    const target = (id && lots.find((l) => l._id === id));
    const searchQuery = [];
    if (phrase) searchQuery.push(`q=${phrase}`);
    if (afterSale) searchQuery.push('aftersale');
    if (!target) {
      if (lotNumber) {
        navigate(`.${searchQuery.length > 0 ? `?${searchQuery.join('&')}` : ''}`);
      }
      return;
    }
    if (target.auction?.number) {
      navigate(`./${target.auction?.number}-${target.number}${searchQuery.length > 0 ? `?${searchQuery.join('&')}` : ''}`);
    } else {
      navigate(`./${target.number}${searchQuery.length > 0 ? `?${searchQuery.join('&')}` : ''}`);
    }
  }, [afterSale, lotNumber, lots, phrase]);

  React.useEffect(() => {
    const controller = new AbortController();
    if (search) {
      if (phrase?.length > 2) {
        fetchSearchResults((data) => { setLots(filterLots(data, newType, auction).map(addRef)) }, setLoading, phrase, type, controller);
      } else {
        navigate('..');
      }
    } else {
      cache.fetchData((data) => { setLots(filterLots(data, newType, auction).map(addRef)) }, setLoading, endpoint, null, controller);
    }
    return () => controller.abort();
  }, [afterSale, phrase, type]);

  return (
    <LotsContext.Provider value={{
      activeLot,
      activeLotImage,
      auction,
      filteredLots,
      loading,
      lots,
      setActiveLot,
      setActiveLotImage,
      setFilteredLots: debounce(setFilteredLots, 250),
      type: newType,
    }}>
      {children}
    </LotsContext.Provider>
  );
};

LotsProvider.propTypes = {
  auction: PropTypes.shape({
    afterSale: PropTypes.bool,
    number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  search: PropTypes.bool,
  type: PropTypes.string,
};

export { LotsProvider, useLots, withLots };

