import React from 'react';
import { withAuthenticationRequired } from 'react-oidc-context';
import { Outlet } from 'react-router-dom';

const Portal = () => {
  return <>
    <Outlet />
  </>;
};

export default withAuthenticationRequired(Portal);
