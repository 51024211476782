import { useMutation } from '@apollo/client';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useAuth } from 'react-oidc-context';

import { sendLogout } from '../../modules/portal.js';
import {
  CLOSE_AUCTION,
  NEXT_LOT,
  PREVIOUS_LOT,

  SET_INCREMENT,
} from '../../schema/portal';

const TestingControls = ({ activeLot = null, auction }) => {
  const { removeUser, user } = useAuth();

  const [closeAuction] = useMutation(CLOSE_AUCTION);
  const [nextLot] = useMutation(NEXT_LOT);
  const [previousLot] = useMutation(PREVIOUS_LOT);

  const [setIncrement] = useMutation(SET_INCREMENT);

  const logout = React.useCallback(async () => {
    await sendLogout(user);
    removeUser();
  }, [removeUser, user]);

  return <div>
    <div>
      <Button onClick={() => { logout(); }}>Logout</Button>
    </div>
    <div>
      <Button onClick={() => { previousLot({ variables: { number: auction.number } }); }}>Previous Lot</Button>
      <Button onClick={() => { nextLot({ variables: { number: auction.number } }); }}>Next Lot</Button>
      <Button onClick={() => { closeAuction({ variables: { number: auction.number } }); }}>Close Auction</Button>
    </div>
    {activeLot ?
      <div>
        Increment:
        <Button onClick={() => { setIncrement({ variables: { lotId: activeLot.portalInfo._id, increment: 50_00 } }); }}>50€</Button>
        <Button onClick={() => { setIncrement({ variables: { lotId: activeLot.portalInfo._id, increment: 100_00 } }); }}>100€</Button>
        <Button onClick={() => { setIncrement({ variables: { lotId: activeLot.portalInfo._id, increment: 250_00 } }); }}>250€</Button>
      </div>
    : null}
  </div>;
};

TestingControls.propTypes = {
  activeLot: PropTypes.shape({
  }),
  auction: PropTypes.shape({
    number: PropTypes.string,
  }).isRequired,
}

export default TestingControls;
