import { AnimatePresence } from 'framer-motion';
import { MatomoProvider, useMatomo } from '@jonkoops/matomo-tracker-react';
import React from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import Copyright from './components/Copyright';
import CookieConsent from './components/CookieConsent';
import Footer from './components/Footer';
import Header from './components/Header';
import Loader from './components/Loader';
import Loading from './components/Loading';
import { useConfig } from './hooks/AppProvider';
import { onClick } from './methods/navigate';
import HelmetConfig from './modules/Helmet';
import matomo from './modules/matomo';
import { AuctionProvider } from './hooks/AuctionProvider';
import { useCookies } from './hooks/CookieProvider';
import Portal from './pages/Portal';
import Auction from './pages/portal/Auction';
import AuctionSelect from './pages/portal/AuctionSelect';

const renderTranslatedRoute = (route, translatedRoute) => (
  <Route
    index={translatedRoute.index || undefined}
    key={translatedRoute.key || translatedRoute.alias}
    path={translatedRoute.alias}
    element={route.load ? <Loader load={route.load} /> : undefined}
  >
  {translatedRoute.redirect && (
    <Route
      index
      element={<Navigate to={translatedRoute.redirect} replace />}
    />
  )}
  {route.children && route.children.map((renderRoute))}
  </Route>
);

const renderRoute = (route) => route.data
  ? route.data.map((translatedRoute) => renderTranslatedRoute(route, translatedRoute))
  : renderTranslatedRoute(route, route);

const Index = () => {
  const { config, loading } = useConfig();
  const location = useLocation();
  const navigate = useNavigate();
  const { trackPageView } = useMatomo();
  const cookieConsent = useCookies();
  const { isAccepted, setShowCookieConsent } = cookieConsent;
  const { routes } = config;

  React.useEffect(() => {
    let timer;
    if (isAccepted('statistics')) {
      // track page view in matomo
      timer = setTimeout(() => {
        trackPageView();
      }, 50);
    }
    if (location.hash === '#cc') {
      setShowCookieConsent(true);
      // clear #cc from url
      navigate(-1);
    }
    return () => clearTimeout(timer);
  }, [location]);

  const stripLocation = () => {
    const pathParts = location.pathname.split('/');
    if (pathParts[1] === 'highlights') {
      return '/highlights';
    }
    return pathParts.slice(0, 3).join('/');
  }

  return loading ? (
    <Loading w="100vw" h="100vh" />
  ) : (
    <div onClick={(e) => onClick(e, navigate)} role="presentation">
      <HelmetConfig />
      <Header />
      <AnimatePresence mode="wait">
        <Routes location={location} key={stripLocation()}>
          {routes && routes.map(renderRoute)}
          <Route exact path="/" element={<Loader load={{ component: 'Home', transition: 'fade' }} />} />
          <Route path="portal" element={<Portal />}>
            <Route path=":auctionNumber" element={<AuctionProvider><Auction /></AuctionProvider>} />
            <Route index element={<AuctionSelect />} />
          </Route>
          <Route path="*" element={<Loader load={{ component: 'NoMatch', transition: 'fade' }} />} />
        </Routes>
      </AnimatePresence>
      <Footer />
      <Copyright />
      <CookieConsent />
    </div>
  );
}

const App = () => {
  const cookieConsent = useCookies();
  const { isAccepted } = cookieConsent;

  return isAccepted('statistics') ? (
    <MatomoProvider value={matomo()}>
      <Index />
    </MatomoProvider>
  ) : <Index />;
}

export default App;
