import React from 'react';
import { useAuth } from 'react-oidc-context';

import PortalComponent from '../components/Portal';

const Portal = () => {
  const auth = useAuth();

  if (auth.activeNavigator == 'signinSilent') {
    return <div>Loggin in</div>;
  }

  if (auth.activeNavigator == 'signoutRedirect') {
    return <div>Loggin out</div>;
  }

  if (auth.isLoading) {
    return <div>Loading</div>;
  }

  if (auth.error) {
    console.error(auth.error);
    // if you get TypeError: failed to fetch when using http from anything other than localhost, it could be caused by this:
    // https://stackoverflow.com/questions/59519988/web-crypto-api-without-ssl
    return <div>Error: {auth.error.message}</div>;
  }

  return <PortalComponent />
};

export default Portal;
