import { useMutation } from '@apollo/client';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';

import {
  userIsAuctioneer,
} from '../../modules/portal';
import {
  OPEN_AUCTION,
} from '../../schema/portal';

const AuctionPending = ({ auction }) => {
  const { user } = useAuth();
  const [openAuction] = useMutation(OPEN_AUCTION);
  const { t } = useTranslation();

  return <>
    <div>{t('portal.auctionPending')}</div>
    {userIsAuctioneer(user) ?
      <div>
        <Button onClick={() => { openAuction({ variables: { number: auction.number } }); }}>
          {t('portal.openAuction')}
        </Button>
      </div>
    : null}
  </>;
};

AuctionPending.propTypes = {
  auction: PropTypes.shape({
    number: PropTypes.string,
  }).isRequired,
}

export default AuctionPending;
