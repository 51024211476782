import { Box, styled, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import i18n from '../../i18n';
import { getPrice } from '../../methods/catalog';
import LotImage from './LotImage';
import LotStatus from './LotStatus';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const Lot = ({ lot }) => {
  const { t } = useTranslation();
  return (
    <StyledBox>
      <TextField
        label={t('portal.lotNumber')}
        value={lot.number}
        disabled
      />
      <LotStatus lot={lot} />
      <Box>
        <Typography variant="h5" component="h3">
          {lot.title}
        </Typography>
      </Box>
      <Box>
        <Typography>
          {t('portal.estimate')}
        </Typography>
        <Typography>
          {getPrice({}, 'portal', lot, { withSymbol: true })}
        </Typography>
      </Box>
      <Box>
        <Typography>
          {t('portal.limit')}
        </Typography>
        <Typography>
          {i18n.currency(lot.limit, { decimals: 0, withSymbol: true })}
        </Typography>
      </Box>
      <Box>
        <LotImage lot={lot} />
      </Box>
    </StyledBox>
  );
};

Lot.propTypes = {
  lot: PropTypes.shape({
    number: PropTypes.number,
    title: PropTypes.string,
    thumbnails: PropTypes.arrayOf(PropTypes.string),
    estimate: PropTypes.number,
    highestimate: PropTypes.number,
    limit: PropTypes.number,
  }).isRequired,
};

export default Lot;
