import React from 'react';
import { useTranslation } from 'react-i18next';

const AuctionPending = () => {
  const { t } = useTranslation();

  return <div>{t('portal.auctionClosed')}</div>;
};

export default AuctionPending;
