import { styled } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const StyledImage = styled('img')(({ type }) => ({
  margin: '0.5rem',
  width: `${type === 'list' ? '30px' : 'auto'}`,
  height: 'auto',
  borderRadius: `${type === 'list' ? '50%' : 0}`,
}));

const LotImage = ({ lot, type = '' }) => {
  const src = lot.thumbnails?.[0] || '';
  return (
    <StyledImage
      src={src}
      alt={lot.title}
      type={type}
    />
  );
};

LotImage.propTypes = {
  lot: PropTypes.shape({
    thumbnails: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
  }).isRequired,
  type: PropTypes.string,
};

export default LotImage;