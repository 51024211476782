import { gql, useQuery } from '@apollo/client';
import React from 'react';
import { Link } from 'react-router-dom';

const AUCTIONS_QUERY = gql`
  query AuctionsQuery {
    auctions {
      _id
      name
      number
    }
  }
`;

const AUCTIONS_SUBSCRIPTION = gql`
  subscription AuctionsSubscription {
    auctionChanged {
      _id
      number
      name
    }
  }
`;

const AuctionSelect = () => {
  const { data, error, loading, subscribeToMore } = useQuery(AUCTIONS_QUERY);

  React.useEffect(() => {
    subscribeToMore({
      document: AUCTIONS_SUBSCRIPTION,
      variables: { auctionId: null },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const auction = subscriptionData.data.auctionChanged;
        if (prev.auctions.find(a => a._id === auction._id)) {
          return {
            ...prev,
            auctions: prev.auctions.map(a => a._id === auction._id ? auction : a),
          };
        }
        return {
          ...prev,
          auctions: [...prev.auctions, auction],
        };
      },
    });
  }, [subscribeToMore]);

  if (loading) return <p>Loading</p>;
  if (error) {
    console.error(error);
    return <p>Error: {error.message}</p>;
  }

  return <div>
    {data.auctions.map(auction =>
      <p key={auction._id}>
        <Link to={auction.number}>A{auction.number} {auction.name}</Link>
      </p>
    )}
  </div>;
};

export default AuctionSelect;
