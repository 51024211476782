import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React from 'react';

import PortalContainer from './Container';
import { useLots } from '../../hooks/LotsProvider';
import { AUCTION_QUERY, AUCTION_SUBSCRIPTION, LOTS_SUBSCRIPTION } from '../../schema/portal';

const Zipper = ({ auctionNumber }) => {
  const { auction, list } = useLots();
  const { data, error, loading, subscribeToMore } = useQuery(AUCTION_QUERY, { variables: { auctionNumber } });

  React.useEffect(() => {
    subscribeToMore({
      document: AUCTION_SUBSCRIPTION,
      variables: { number: auctionNumber },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const auction = subscriptionData.data.auctionChanged;
        return {
          ...prev,
          auction,
        };
      },
    });
    subscribeToMore({
      document: LOTS_SUBSCRIPTION,
      variables: { auctionNumber },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const lot = subscriptionData.data.lotChanged;
        if (prev.lots.find(l => l._id === lot._id)) {
          return {
            ...prev,
            lots: prev.lots.map(l => l._id === lot._id ? lot : l),
          };
        }
        return {
          ...prev,
          lots: [...prev.lots, lot],
        };
      },
    });
  }, [auctionNumber, subscribeToMore]);

  const combinedAuction = React.useMemo(() => ({
    ...auction,
    portalInfo: data && data.auction,
  }), [auction, data && data.auction]);

  const lots = React.useMemo(() => {
    if (!(data && data.lots && list)) return [];
    return list
      .map(l => ({ ...l, portalInfo: data.lots.find(pl => pl.reference === l._id)}))
      .filter(l => !!l.portalInfo);
  }, [data && data.lots, list]);

  const activeLot = React.useMemo(() => {
    const portalActiveLotId = data && data.auction && data.auction.currentLotId;
    return lots.find(l => l.portalInfo._id === portalActiveLotId);
  }, [data, lots]);

  const anyLoading = !auction || !list || loading;

  if (error) {
    console.error(error);
    return <div>GQL Error: {error.message}</div>;
  }

  if (anyLoading) {
    return <div>Loading</div>;
  }

  return <div>
    <PortalContainer activeLot={activeLot} auction={combinedAuction} lots={lots} />
  </div>;
};

Zipper.propTypes = {
  auctionNumber: PropTypes.string.isRequired,
};

export default Zipper;
