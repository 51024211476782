import { List, ListItem, ListItemText } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import i18n from '../../i18n';

const getPrimary = (entry, t) => {
  switch (entry.type) {
    case 'bid':
      return t(
        `portal.protocol.${entry.type}`,
        { ...entry, value: i18n.currency(entry.bid.newAmount) },
      );
    case 'increment':
      return t(
        `portal.protocol.${entry.type}`,
        { ...entry, value: i18n.currency(entry.increment),
        });
    default:
      return t(`portal.protocol.${entry.type}`, entry);
  }
};

const getSecondary = (entry, t) => {
  return t('portal.protocol.date', { date: new Date(entry.date) });
};

const Protocol = ({ lot }) => {
  const { t } = useTranslation();

  const entries = React.useMemo(() => {
    const proto = (lot && lot.portalInfo && lot.portalInfo.protocol) || [];
    return [...proto].sort((a, b) => a.date < b.date ? 1 : a.date > b.date ? -1 : 0);
  }, [lot]);

  return <List>
    {entries.map((entry) => (
      <ListItem key={`${entry.date}`}>
        <ListItemText primary={getPrimary(entry, t)} secondary={getSecondary(entry, t)} />
      </ListItem>
    ))}
  </List>;
};

Protocol.propTypes = {
  lot: PropTypes.shape({
    portalInfo: PropTypes.shape({
      protocol: PropTypes.arrayOf(PropTypes.shape({
        bid: PropTypes.shape({
          newAmount: PropTypes.number,
          oldAmount: PropTypes.number,
        }),
        date: PropTypes.string.isRequired,
        increment: PropTypes.number,
        type: PropTypes.string.isRequired,
      })),
    }),
  }).isRequired,
};

export default Protocol;
