import { Box, styled, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import LotImage from './LotImage';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 0,
  padding: 0,
  color: theme.palette.text.secondary,
  border: '1px solid #ccc',
  borderRadius: '1rem',
  marginTop: '0.5rem',
}));

const LotRow = styled(Box)({
  margin: '0.25rem',
  padding: '0.25rem',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  borderBottom: '1px solid #ccc',
  '&:last-child': {
    borderBottom: 'none',
  },
});

const UpcommingLots = ({ lots }) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography sx={{ mt: '2rem' }}>
        {t('portal.upcommingLots')}
      </Typography>
      <StyledBox>
        {lots.map((lot) => (
          <LotRow key={lot.number}>
            <LotImage lot={lot} type="list" />
            {t('portal.lot')} {lot.number} - {lot.title}
          </LotRow>
        ))}
      </StyledBox>
    </Box>
  );
};

UpcommingLots.propTypes = {
  lots: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default UpcommingLots;