import { Box, Typography } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getTranslation } from '../../methods/common';

const AuctionHeading = ({ auction }) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography variant="h1" sx={{ lineHeight: 1 }}>
        {t('portal.auctionNumber', { number: auction?.number })}
        <br />
        <small>{getTranslation(auction, 'title')}</small>
      </Typography>
      <Typography variant="h2">
        {t('general.auctionDate', { date: moment(auction.date).format('LL') })}
      </Typography>
    </Box>
  );
};

AuctionHeading.propTypes = {
  auction: PropTypes.shape({
    date: PropTypes.string,
    number: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

export default AuctionHeading;