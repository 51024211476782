export default {
  translation: {
    archive: {
      title: 'Archive',
      subtitle: 'online catalogues of past sales',
      search: {
        title: 'Suchen',
        label: 'Archive search',
      },
    },
    auctions: {
      button: {
        label: 'Go to auction',
      },
      empty: 'No public auctions found.',
      nonPlanned: 'There are no planned auctions.',
    },
    bidform: {
      address: '<h2 class="logo">RIPPON BOSWELL & CO. GmbH</h2>Friedrichstrasse 45<br />D - 65185 Wiesbaden<br />Germany<p>Phone: +49 611 334430<br /><strong>Fax: +49 611 3344326</strong></p>',
      auctionPassed: 'There is currently no auction for which you can place bids.',
      info: 'I wish to participate in the forthcoming auction on <strong>{{date}}</strong> with my written resp. telephone bid(s). Please bid on my behalf resp. phone me for the following lot(s) up to the hammer price(s) written below, to be executed as cheaply as is permitted by other bids or reserves. I agree to be bound to the Conditions of Sale applicable to this auction as stated in the printed catalogue and published on the Rippon Boswell homepage. I agree to pay a buyer\'s premium of <strong>{{provision}}%</strong> on the hammer price(s) and VAT at the current rate (<strong>{{vat}}%</strong>) on the premium.',
      title: 'Absentee Bid Form',
      success: 'Please print out the created PDF and send it signed either by post or fax to the above address.',
      date: 'Date',
      signature: 'Signature',
      pdf: {
        address: 'RIPPON BOSWELL & CO. GmbH\nFriedrichstrasse 45\nD - 65185 Wiesbaden\nGermany\n\nTel.: +49(0)611 - 33 44 30\nTelefax: +49(0)611 - 3 34 43 26',
        filename: 'Commission Bidding Form',
        info: 'Please bid on my behalf at the auction on {{date}}, for the following lot number (the following lot numbers) up to the amount specified (the amounts specified). All commissions must be executed at the lowest possible hammer prices and according to the Conditions of Sale printed in the catalogue to which I hereby expressiv agree by signing this form. The auctioneers will charge a buyer\'s premium of {{provision}} percent on the hammer price, plus German VAT of {{vat}} percent on the buyer\'s premium.',
        title: 'Commission Bidding Form',
      },
    },
    catalog: {
      back: {
        archive: 'Archive',
        results: 'Results',
      },
      details: {
        open: 'View details',
        close: 'Close details',
      },
      emptyWishlist: 'You have not saved any lots to your wishlist.',
      exchangeRates: {
        title: 'Exchange rates',
        eur: '{{eur}} EUR',
        gbp: '{{gbp}} GBP',
        usd: '{{usd}} USD',
      },
      filter: {
        byBookmarks: 'Filter by wishlist',
        hide: 'Hide filter',
        reset: 'Reset filter',
        show: 'Show filter',
      },
      fullscreen: {
        close: 'Close fullscreen view',
        open: 'Open  fullscreen view',
      },
      fulltextsearch: 'Full-text search',
      literature: 'Lit.:',
      lotCount: '{{count}} Lots',
      lotCount_one: '{{count}} Lot',
      lotCountFiltered: '{{count}} of {{total}} Lots',
      lotsPerPage: 'Lots per page',
      noAuction: 'No Auction found',
      noLots: 'No lots found',
      origin: 'Origin',
      print: 'Print',
      published: 'Publ.:',
      sortBy: 'Sort by',
      sortByLotNoAsc: 'Lot Number ascending',
      sortByLotNoDesc: 'Lot Number descending',
      sortByEstimateAsc: 'Estimate ascending',
      sortByEstimateDesc: 'Estimate descending',
      sortByResultAsc: 'Result ascending',
      sortByResultDesc: 'Result descending',
      sortByOriginAsc: 'Origin ascending',
      sortByOriginDesc: 'Origin descending',
      sortByWidthAsc: 'Width ascending',
      sortByWidthDesc: 'Width descending',
      sortByHeightAsc: 'Height ascending',
      sortByHeightDesc: 'Height descending',
      title: 'Online Catalogue {{number}}',
      view: {
        grid: 'Grid view',
        list: 'List view',
        table: 'Table view',
      },
    },
    catalogorder: {
      auctionCatalog: 'Auction Catalogue',
      catalogSubscription: 'Catalogue subscription starting with',
      currentCatalog: 'Current Catalogue',
      title: 'Order Catalogue',
      info: 'Please make sure to fill out the form completely. Otherwise we can not accept your order.',
      incVatAndPostageDe: '{{price}},- {{currency}} including VAT and postage Inland',
      incVatAndPostageEu: '{{price}},- {{currency}} including VAT and postage Europe',
      incVatAndPostageOverseas: '{{price}},- {{currency}} including VAT and postage overseas',
      order: 'Order:',
      paymentType: {
        label: 'Payment Method:',
        visa: 'VISA/Mastercard',
        wiretransfer: 'Wire Transfer',
      },
      send: 'Submit',
      success: 'Your catalog order was sent successfully.',
      error: {
        'captcha-failed': 'Captcha validation failed',
      },
    },
    contact: {
      title: 'Contact',
      info: 'Use this form to send us a message.<br />Your note will be fowarded to <strong>info@rippon-boswell-wiesbaden.de</strong>.',
      warning: 'Please make sure to fill out all fields marked with an asterisk <strong>*</strong>.',
      success: 'Your contact request was sent successfully.',
      error: {
        'captcha-failed': 'Captcha validation failed',
      },
    },
    cookieConsent: {
      title: 'Cookie-Settings',
      info: 'This website uses cookies and similar functions to process end device information and personal data. The processing serves to integrate content, external services and elements of third parties as well as statistical analysis/measurement. Depending on the function, data is passed on to third parties. Your consent is always voluntary and can be refused or revoked at any time via the link "Cookie settings" at the bottom of the page.',
      button: {
        accept: 'Accept all',
        decline: 'Only essential',
        imprint: 'Imprint',
        imprintLink: '/about-us/imprint',
        moreDetails: 'More details',
        lessDetails: 'Less details',
        privacyPolicy: 'Privacy Policy',
        privacyPolicyLink: '/about-us/privacypolicy',
        save: 'Save',
        unblockFunctional: 'Allow Functional Cookies',
        unblockMarketing: 'Allow Marketing Cookies',
      },
      cookies: {
        i18next: {
          purpose: 'Saves the language selection.',
          validity: 'Persistent',
        },
        hideCookieConsent: {
          purpose: 'Saves across sessions whether the cookie settings should be displayed.',
          validity: 'Persistent',
        },
        acceptedCookieGroups: {
          purpose: 'Saves across sessions which cookie groups have been approved.',
          validity: 'Persistent',
        },
        hcaptcha: {
          purpose: 'Used on forms to ensure you are a real person and not a bot.',
          validity: 'Session',
          blocked: 'We use HCaptcha to prevent spam requests. In order to use the form, you must accept functional cookies.',
        },
        pkSess: {
          purpose: 'Used by Matomo to record current visitor access.',
          validity: '30 minutes',
        },
        pkId: {
          purpose: 'Used by Matomo to identify the visitor.',
          validity: '13 months',
        },
        googlemaps: {
          purpose: 'Google Maps map display',
          validity: 'Session',
          blocked: 'In order to use the interactive Google map, you must accept marketing cookies.'
        },
      },
      group: {
        essential: 'Essential Cookies',
        functional: 'Functional Cookies',
        statistics: 'Statistics Cookies',
        markerting: 'Marketing Cookies',
      },
      table: {
        name: 'Name',
        provider: 'Provider',
        purpose: 'Purpose',
        validity: 'Validity',
      },
    },
    dates: {
      title: 'Auction dates {{date, YYYY}}',
      info: 'We invite you to consign your carpets and textiles to our successful auctions as early as possible. For a first opinion please send us photos or digital images of the relevant objects, giving measurements and a brief condition report prior to a visit to Wiesbaden or shipment for appraisal. <p>We are also prepared to evaluate important objects or larger collections at your home.</p>',
      dateFormat: 'Do MMM YYYY',
      hour: 'at {{hour}}',
      hourFormat: 'h a',
      longDateFormat: 'dddd, MMMM Do YYYY [at] h a',
    },
    form: {
      address: 'Address',
      consent: {
        title: 'Consent to the processing of personal data:',
        label: 'I agree to the processing of my personal data and have read the privacy policy.',
        help: 'We use your personal data given here only for the purpose of answering your questions [request] and do not share your data with third parties. If no business relationship or business relationship results from this inquiry, your data will be deleted after fulfillment of the purpose. You can object to the processing and storage at any time by sending an e-mail to info@rippon-boswell-wiesbaden.de',
      },
      country: 'Country',
      email: 'E-Mail Address',
      error: {
        address: {
          required: 'Please enter your address.',
        },
        bid: {
          required: 'Please enter a maximum bid.',
          min: 'Please enter a maximum bid.',
        },
        captcha: {
          required: 'Please confirm the Captcha query.',
        },
        consent: {
          required: 'Please agree to the data processing so that we can process your request.',
        },
        country: {
          required: 'Please provide the state you are living in.',
        },
        email: {
          required: 'Please enter your e-mail address.',
          valid: 'Please enter a valid email address.',
          min: 'Please enter the minimum number of characters',
        },
        firstname: {
          required: 'Please enter your first name.',
        },
        lastname: {
          required: 'Please enter your last name.',
        },
        message: {
          required: 'Please enter your question or short message.',
        },
        mixed: {
          default: 'Please correct this field.',
          required: 'Please fill out this field.',
        },
        name: {
          required: 'Please enter your name.',
        },
        payment: {
          required: 'Please select the payment method.',
        },
        phone: {
          required: 'Please enter your telephone Number.',
        },
        street: {
          required: 'Please enter your street and house number.',
        },
        subscription: {
          required: 'Please choose a catalog subscription or the current edition of the online catalogue.',
        },
        token: {
          required: 'Please confirm the Captcha query.',
        },
        zip: {
          required: 'Please enter your zip code and place of residence.',
        },
      },
      fax: 'Fax',
      firstname: 'Firstname',
      lastname: 'Lastname',
      name: 'Name',
      passportReminder: 'Please send a copy of your ID.',
      phone: 'Phone',
      print: 'Print',
      salecode: 'Sale Code No.',
      send: 'Send',
      sender: 'Sender',
      street: 'Street',
      streetAndNo: 'Street And No.',
      message: 'My Question Or Short Message',
      zipAndCity: 'Postal Code And City',
    },
    general: {
      add: 'Add',
      address: 'Friedrichstraße 45,<br />D-65185 Wiesbaden,<br />Phone +49 (0)611 – 33 44 30<br />Email: info@rippon-boswell-wiesbaden.de<br />www.rippon-boswell-wiesbaden.de',
      all: 'All',
      auctionDate: 'on {{date}}',
      close: 'Close',
      filename: '{{date, YYYY-MM-DD}} {{name}}',
      filter: 'Filter',
      goToOverview: 'Go to overview',
      home: 'Home',
      letterDate: 'Wiesbaden, {{date, DD.MM.YYYY}}',
      loading: 'loading...',
      longDateFormat: '{{date, dddd, Do MMMM YYYY}}',
      menu: 'Menu',
      more: 'more',
      page: 'Page',
      of: ' of ',
      scrollTop: 'To the top',
    },
    highlights: {
      title: 'Highlights',
      subtitle: 'Highlights of our auctions since autumn 2007',
    },
    language: 'Language',
    location: {
      title: 'Location',
      linkTitle: 'Larger Map View',
    },
    lots: {
      add_one: 'Add lot',
      add_other: 'Add lots',
      afterSale: 'After Sale',
      age: 'Age',
      bid: 'Highest bid in €',
      bidForLot: 'Bid on lot.',
      bookmark: {
        remove: 'Remove from wishlist',
        save: 'Add to wishlist',
      },
      description: 'Title Description',
      details: {
        hide: 'Hide details',
        show: 'Show details',
      },
      dimensions: 'Size',
      estimate: 'Estimate',
      estimateInEUR: 'Estimate in {{currency}}',
      filename: {
        archive: 'Online Catalogue A{{auction.number}}',
        highlights: 'Highlights',
        'online-catalog': 'Online Catalogue A{{auction.number}}',
        results: 'Results A{{auction.number}}',
      },
      filterByBookmarks: 'Filter by bookmarked',
      headCellLabel: {
        image: '',
        number: 'Lot No.',
        title: 'Title Description',
        age: 'Age',
        dimensions: 'Size',
        estimate: 'Estimate in EUR',
      },
      images: {
        alternate: 'Alternate lot views',
        next: 'Next image',
        previous: 'Previous image',
      },
      info: {
        hide: 'Hide informationen',
        show: 'Show informationen',
      },
      label: 'Lots',
      limit: 'Limit',
      limitIn: 'Limit in {{currency}}',
      navigate: {
        next: 'Further to lot {{number}}',
        previous: 'Back to lot {{number}}',
      },
      noBidsError_one: 'Please enter a highest bid for the selected lot.',
      noBidsError_other: 'Please enter highest bids for the selected lots.',
      noSelection: 'No lots selected',
      noSelectionError: 'Please select at least one lot.',
      number: 'Lot No.',
      origin: 'Origin',
      perPage: 'Lots per page:',
      removeBid: 'Remove bid',
      result: 'Result, including buyer’s premium',
      resultIn: 'Result in {{currency}}, including buyer’s premium',
      rowDisplay: '{{page}} of {{count}}',
      select: 'Select Lots',
      selectAll: 'Select all lots',
      selectWithCount_one: '{{count}} lot selected',
      selectWithCount_other: '{{count}} lots selected',
      sizeComparison: {
        hide: 'Hide size comparison',
        show: 'Show size comparison',
      },
      surcharge: 'Hammerprice',
      surchargeIn: 'Hammerprice in {{currency}}',
      title: 'Lot Title',
    },
    news: {
      title: 'Reports',
    },
    newsletter: {
      title: 'Current Newsletter',
    },
    portal: {
      auctionNumber: 'Auction {{number}}',
      auctionPending: 'Please wait for the auctioneer to open this auction.',
      bid: 'Bid',
      closeAuction: 'Close auction',
      closeLot: 'Close',
      currentBid: 'Current Bid',
      estimate: 'Estimate',
      hammerLot: 'Hammer',
      limit: 'Limit',
      lot: 'Lot',
      lotClosed: 'Lot closed',
      lotNumber: 'Lot No.',
      lotPending: 'Please wait for auctioneer to open bidding on this lot.',
      lotStatus: {
        CLOSED: 'Closed',
        LIVE: 'Waiting',
        ONE: '1.',
        PENDING: 'Pending',
        THREE: '3...',
        TWO: '2..',
      },
      openAuction: 'Open auction',
      openLot: 'Open',
      placeBid: 'Place Bid',
      reopenLot: 'Reopen',
      upcommingLots: 'Upcoming Lots',
    },
    results: {
      title: 'Results fo our auctions',
    },
    search: {
      title: 'Search results',
      subtitle: 'Searched for {{phrase}} in all auctions',
    },
    subscribe: {
      title: 'Newsletter subscription',
      consent: {
        title: 'Consent to the processing of personal data:',
        label: 'I consent to the processing of my personal data and have read the privacy policy.',
        help: 'We use your personal data provided here to keep you informed about our activities via e-mail. You can withdraw your decision at any time by clicking the unsubscribe link in the footer of any email you receive from us or by using the unsubscribe form on our website. We treat your data with care.',
      },
      error: {
        default: 'Errors occured signing up for our newsletter.',
        'subscriber-exists': 'The e-mail address is already in the mailing list.',
        'captcha-failed': 'Captcha validation failed',
      },
      send: 'Subscribe',
      success: 'You have successfully registered for our newsletter. Please confirm your email address using the link sent to you.',
    },
    unsubscribe: {
      error: {
        default: 'Errors occured unsubscibing from our newsletter.',
        'captcha-failed': 'Captcha validation failed',
      },
      title: 'Newsletter unsubscription',
      submit: 'Unsubscribe',
      success: 'You have successfully unsubscribed from our newsletter distribution list.',
    },
    zoomify: {
      zoomIn: 'Zoom in',
      zoomOut: 'Zoom out',
      reset: 'Reset view',
      rotateLeft: 'Rotate left',
      rotateRight: 'Rotate right',
    },
  }
}
