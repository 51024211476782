import React from 'react';
import { useParams } from 'react-router-dom';

import Zipper from '../../components/portal/Zipper';
import { useAuctions } from '../../hooks/AuctionProvider';
import { LotsProvider } from '../../hooks/LotsProvider';

const Auction = () => {
  const { auctionNumber } = useParams();
  const { list } = useAuctions();

  const auction = React.useMemo(() => list.find(a => a.number == auctionNumber), [auctionNumber, list]);

  if (!auction) {
    return <div>Not Found</div>;
  }

  return <div>
    <LotsProvider auction={auction}>
      <Zipper auctionNumber={auctionNumber} />
    </LotsProvider>
  </div>;
};

export default Auction;
