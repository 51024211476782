import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import { useCache } from './CacheProvider';

const AuctionContext = React.createContext(null);

const useAuctions = () => {
  const { auctions, loading } = React.useContext(AuctionContext);

  const getActiveAuction = () => auctions.filter((a) => a.catalog)[0];

  const getCatalogOrderAuction = () => {
    const qualified = auctions.filter((a) => moment(a.date).add(3, 'weeks') > moment());
    return qualified.length ? qualified[qualified.length - 1] : getActiveAuction();
  };

  const getCurrentAuctions = () => auctions.filter((a) => moment(a.date).year() === moment().year()).sort((a, b) => moment(a.date).diff(moment(b.date)));

  const getPastAuctions = () => auctions.filter((a) => a.catalog && moment(a.date) < moment());

  const getResults = () => getPastAuctions().filter((a) => a.results);

  return {
    activeAuction: getActiveAuction(),
    archive: getPastAuctions(),
    catalogOrderAuction: getCatalogOrderAuction(),
    currentAuctions: getCurrentAuctions(),
    list: auctions,
    loading,
    results: getResults(),
  };
};

// eslint-disable-next-line react/display-name
const withAuctions = (Component) => (props) => {
  const auctions = useAuctions();

  return <Component {...props} auctions={auctions} />;
};

const AuctionProvider = ({ children = null }) => {
  const cache = useCache();
  const [auctions, setAuctions] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const controller = new AbortController();
    cache.fetchData(setAuctions, setLoading, 'auctions', null, controller);
    return () => controller.abort();
  }, []);

  return (
    <AuctionContext.Provider value={{ auctions, loading }}>
      {children}
    </AuctionContext.Provider>
  );
};

AuctionProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export { AuctionProvider, useAuctions, withAuctions };

