import { Box, styled } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  justifyContent: 'space-between',
}));

const LotStatus = ({ lot }) => {
  const { user } = useAuth();
  const { t } = useTranslation();

  const isHolder = React.useMemo(() => {
    return lot && lot.portalInfo && user && user.profile && lot.portalInfo.currentHolder === user.profile.sub;
  }, [lot && lot.portalInfo, user]);

  const status = React.useMemo(() => {
    if (!lot || !lot.portalInfo || !lot.portalInfo.status) {
      return t('portal.lotStatus.PENDING');
    }
    return t(`portal.lotStatus.${lot.portalInfo.status}`);
  }, [lot && lot.portalInfo, t]);

  return <StyledBox>
    <div>{status}</div>
    <div>{isHolder ? 'HOLDER' : ''}</div>
  </StyledBox>;
};

LotStatus.propTypes = {
  lot: PropTypes.shape({
    portalInfo: PropTypes.shape({
      currentHolder: PropTypes.string,
      status: PropTypes.string,
    }),
  }).isRequired,
};

export default LotStatus;
