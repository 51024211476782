import PropTypes from 'prop-types';
import React from 'react';
import { AuthProvider } from 'react-oidc-context';

const authority = process.env.REACT_APP_OIDC_AUTHORITY;

const OAuthProvider = ({ children = null }) => (
  <AuthProvider
    authority={authority}
    automaticSilentRenew={true}
    client_id="frontend"
    loadUserInfo={true}
    onSigninCallback={() => {
      window.history.replaceState({}, document.title, window.location.pathname);
    }}
    redirect_uri={`${location.origin}/portal`}
  >
    {children}
  </AuthProvider>
);

OAuthProvider.propTypes = {
  children: PropTypes.node,
};

export default OAuthProvider;
