import { Card, CardContent, Grid2 as Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import AuctionClosed from './AuctionClosed';
import AuctionPending from './AuctionPending';
import AuctionProgress from './AuctionProgress';
import AuctionHeading from './AuctionHeading';
import Content from '../Content';
import UpcomingLots from './UpcomingLots';
import Lot from './Lot';
import Bid from './Bid';
import Protocol from './Protocol';

import {
  auctionIsPending,
  auctionIsLive,
  auctionIsClosed,
} from '../../modules/portal';

import TestingControls from './TestingControls';

const PortalContainer = ({ activeLot = null, auction, lots }) => {
  return (
    <Content>
      <TestingControls activeLot={activeLot} auction={auction} />
      <AuctionHeading auction={auction} />
      {auctionIsPending(auction) ?
        <AuctionPending auction={auction} />
      : null}
      {auctionIsClosed(auction) ?
        <AuctionClosed />
      : null}
      {auctionIsLive(auction) ?
        <Grid container spacing={4}>
          <Grid size={4}>
            <AuctionProgress activeLot={activeLot} auction={auction} lots={lots} />
            <UpcomingLots lots={lots} />
          </Grid>
          <Grid size={8}>
            <Card variant="outlined">
              <CardContent>
                {activeLot ?
                  <Grid container spacing={2}>
                    <Grid size={6}>
                      <Lot lot={activeLot} />
                    </Grid>
                    <Grid size={6}>
                      <Bid lot={activeLot} />
                      <Protocol lot={activeLot} />
                    </Grid>
                  </Grid>
                :
                  <div>No active Lot</div>
                }
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      : null}
    </Content>
  );
};

PortalContainer.propTypes = {
  activeLot: PropTypes.shape({
  }),
  auction: PropTypes.shape({
  }).isRequired,
  lots: PropTypes.arrayOf(PropTypes.shape({
  })).isRequired,
}

export default PortalContainer;
