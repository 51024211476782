import axios from 'axios';

export const auctionIsPending = (auction) => auction && auction.portalInfo && auction.portalInfo.status === 'PENDING';

export const auctionIsLive = (auction) => auction && auction.portalInfo && auction.portalInfo.status === 'LIVE';

export const auctionIsClosed = (auction) => auction && auction.portalInfo && auction.portalInfo.status === 'CLOSED';

export const lotIsPending = (lot) => lot && lot.portalInfo && lot.portalInfo.status === 'PENDING';

export const lotIsLive = (lot) => lot && lot.portalInfo && ['LIVE', 'ONE', 'TWO', 'THREE'].includes(lot.portalInfo.status);

export const lotIsClosed = (lot) => lot && lot.portalInfo && lot.portalInfo.status === 'CLOSED';

export const getUserName = (user) => (user && user.profile && user.profile.name) || '';

export const userIsAuctioneer = (user) => user && user.profile && user.profile.claims
  && user.profile.claims.includes('auctioneer');

export const sendLogout = async (user) => {
  return axios.post(
    `${process.env.REACT_APP_OIDC_AUTHORITY}/oidc/logout`,
    {},
    { headers: { Authorization: `Bearer ${user.access_token}` } },
  );
};
