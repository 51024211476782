import { Box, LinearProgress, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const LinearProgressWithLabel = ({ value }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          variant="determinate"
          value={value}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          sx={{ color: 'text.secondary' }}
        >{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
};

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

const AuctionProgress = ({ activeLot = null, auction, lots }) => {
  const progress = React.useMemo(() => {
    if (auction.portalInfo.state === 'CLOSED') {
      return 100;
    }
    if (!activeLot || lots.length === 0) {
      return 0;
    }
    const lotIndex = lots.findIndex(l => l._id === activeLot._id);
    return Math.min(99, lotIndex / (lots.length - 1) * 100);
  }, [activeLot, auction, lots]);

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel value={progress} />
    </Box>
  );
};

AuctionProgress.propTypes = {
  activeLot: PropTypes.shape({
  }),
  auction: PropTypes.shape({
    portalInfo: PropTypes.shape({
      state: PropTypes.string,
    }),
  }).isRequired,
  lots: PropTypes.arrayOf(PropTypes.shape({
  })).isRequired,
}

export default AuctionProgress;
